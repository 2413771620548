import Wrapper from './screens/Wrapper'
import Header from "./screens/Header";
import Screen1 from "./screens/Screen1";
import Screen2 from "./screens/Screen2";
import Screen3 from "./screens/Screen3";
import Screen4 from "./screens/Screen4";
import Screen5 from "./screens/Screen5";
import Screen6 from "./screens/Screen6";
import Screen8 from "./screens/Screen8";
import Screen9 from "./screens/Screen9";
import Screen10 from "./screens/Screen10";
import Screen11 from "./screens/Screen11";

import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "react-custom-scroll/dist/customScroll.css"
import {useState} from "react";

function App() {
    const [freeze, setFreeze] = useState(false);

    return (
        <Wrapper>
            <Header />
            <Screen1 />
            <Screen2 />
            <Screen3 />
            <Screen4 />
            <Screen5 />
            <Screen6 />
            {/*<Screen7 />*/}
            <Screen8 />
            <Screen9 />
            <Screen10 />
            <Screen11 />
        </Wrapper>
    );
}

export default App;
