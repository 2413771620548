import axios from 'axios';
import React from "react";

const instance = axios.create({
    baseURL: 'https://api.eatandsplit.com/api/v1/landing/'
});

class Forms {
    getRecaptchaKey() {
        return '6LfKxysjAAAAAJ58BUwfOyYbGunp7UgvyF1EsNh2';
    }

    async feedback(data) {
        return instance.post("sendFeedbackMail", data);
    }

    async request(data) {
        return instance.post("sendRequestMail", data);
    }
}


export default new Forms();