import React from "react";
import '../styles/screen8.scss'
import withUseSwipeable from "../components/withUseSwipeable";
import SlideComponent from "../components/SlideComponent";

export default withUseSwipeable(class Screen8 extends SlideComponent {
    constructor(props) {
        super(props);

        this.state.slides = [{
            number: 0,
            title: 'для гостей',
            slide: <div className={"images slide_1"}>
                <div>
                    <div className={"screen_1"}></div>
                    <p>лояльность</p>
                    <span>позволяет избежать любых<br/>неудобств при оплате</span>
                </div>
                <div>
                    <div className={"screen_2"}></div>
                    <p>удобство</p>
                    <span>самый быстрый и приятный<br/>способ разделить счет на<br/>компанию</span>
                </div>
            </div>
        },{
            number: 1,
            title: 'для ресторанов',
            slide: <div className={"images  slide_2"}>
                <div>
                    <div className={"screen_3"}></div>
                    <p>скорость</p>
                    <span>повышение скорости<br/>и качества обслуживания</span>
                </div>
                <div>
                    <div className={"screen_4"}></div>
                    <p>бесплатно</p>
                    <span>стоимость Eat&Split для<br/>заведений - <b>0 р.</b></span>
                </div>
            </div>
        },{
            number: 2,
            title: 'для официантов',
            slide: <div className={"images  slide_3"}>
                <div>
                    <div className={"screen_5"}></div>
                    <p>доход</p>
                    <span>рост чаевых от <b>40%</b></span>
                </div>
                <div>
                    <div className={"screen_6"}></div>
                    <p>время</p>
                    <span>экономит время и нервы при<br/>обслуживании столов</span>
                </div>
            </div>
        }];
    }

    render() {
        return <div id={"screen8"}>
            <div className={"benefits"}>
                <h2>наши преимущества</h2>

                <div className={"slides"} {...this.props.swipe}>
                    {this.state.slides.map(slide => {
                        return <div className={this.state.slide === slide.number ? ("active " + slide.bg) : slide.bg}>
                            <h3>{slide.title}</h3>
                            {slide.slide}
                        </div>
                    })}
                </div>

                <div className={"pages"}>
                    {this.state.slides.map(slide => {
                        return <div onClick={this.slide.bind(this, slide.number)} className={this.state.slide === slide.number ? "active" :""} />
                    })}
                </div>
            </div>
        </div>;
    }
});