import React from "react";
import withUseSwipeable from "./withUseSwipeable";


export default class SlideComponent extends React.Component {
    lastInterval = 0;

    state = {
        slides: [],
        slide: 0,
        forward: "next",
        auto: 0
    }

    constructor(props) {
        super(props);


        if(this.props.onSwipedLeft !== undefined) {
            this.props.onSwipedLeft((ev) => {
                if(this.state.slide < this.state.slides.length - 1) {
                    this.setState({
                        slide: this.state.slide+1,
                        forward: "next"
                    })
                }
                else {

                    console.log(333);
                    this.setState({
                        slide: 0,
                        forward: "next"
                    })
                }
            });
        }

        if(this.props.onSwipedRight !== undefined) {
            this.props.onSwipedRight((ev) => {
                if(this.state.slide > 0) {
                    this.setState({
                        slide: this.state.slide-1,
                        forward: "prev"
                    })
                }
                else {
                    this.setState({
                        slide: this.state.slides.length - 1,
                        forward: "prev"
                    })
                }
            });
        }

        setInterval(() => {
            if(this.state.auto > 0) {
                this.lastInterval++;

                if(this.lastInterval > this.state.auto) {
                    this.slide(this.state.slide >= this.state.slides.length - 1 ? 0 : this.state.slide + 1);
                }
            }

        }, 1000);
    }

    slide(number, reset) {
        console.log(number);

        this.lastInterval = 0;
        reset = reset === true;

        if(number === this.state.slide) {
            if(reset) {
                this.setState({
                    slide: -1
                });
            }

            return;
        }

        this.setState({
            slide: number,
            forward: number > this.state.slide ? "next" : "prev"
        })
    }
};