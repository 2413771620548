import * as React from "react";
import {useSwipeable} from "react-swipeable";

export default function withUseSwipeable(Component) {

    return props => {
        let left = () => {}
        let right = () => {};
        let swiping = () => {};
        let swiped = () => {};

        let onSwipedLeft = (func) => {
            left = func;
        }

        let onSwipedRight = (func) => {
            right = func;
        }

        let onSwiping = (func) => {
            swiping = func;
        }

        let onSwiped = (func) => {
            swiped= func;
        }



        const val = useSwipeable({
            preventScrollOnSwipe: false,
            trackMouse: true,
            onSwipedLeft: (ev) => {
                left(ev);
            },
            onSwipedRight: (ev) => {
                right(ev);
            },
            onSwiping: (ev) => {
                swiping(ev);
            },
            onSwiped: (ev) => {
                swiped(ev);
            }
        });

        return <Component swipe={val} onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight} onSwiping={onSwiping} onSwiped={onSwiped}  />;
    }
}