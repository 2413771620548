import React from "react";
import '../styles/screen2.scss'
import withUseSwipeable from "../components/withUseSwipeable";
import SlideComponent from "../components/SlideComponent";

export default withUseSwipeable(class Screen2 extends SlideComponent {
    constructor(props) {
        super(props);

        this.state.slides = [{
            number: 0,
            title: 'Сканируйте QR код',
            desc: <p>чтобы воспользоваться нашим сервисом,<br/> гостям нужно отсканировать камерой своего<br/> смартфона QR-код (на чеке или другом<br/> носителе).</p>,
            bg: 'slide_1'
        },{
            number: 1,
            title: 'Выберите свои блюда',
            desc: <p>на главной странице каждый может<br/> выбрать свои позиции, разделить одно<br/> блюдо на несколько человек, а также<br/> поделить счёт поровну между всеми.</p>,
            bg: 'slide_2'
        },{
            number: 2,
            title: 'Оплата и чаевые',
            desc: <p>далее гости выбирают размер чаевых<br/> официанту от своей суммы и производят<br/> оплату в одно нажатие!</p>,
            bg: 'slide_3'
        },{
            number: 3,
            title: 'Финальная страница',
            desc: <p>после оплаты гости могут увидеть<br/> статус своего счёта, оставить отзыв или<br/> вернуться на предыдущую страницу.</p>,
            bg: 'slide_4'
        }];

        this.state.auto = 15;
    }

    render() {
        return <div id={"screen2"}>
            <div className={"screen-body"}>
                <img src={require("../images/screen2/top_image.png")} />

                <div className={"slider-wrapper"}>
                    <div className={"manager"}>
                        <div className={"info"}>
                            <div className={"how-it-works-svg"}/>
                            <h2>как это работает ?</h2>
                        </div>

                        <div className={"content"}>
                            {this.state.slides.map(slide => {
                                return <div className={this.state.slide === slide.number ? "active" : ""}>
                                    <h3>{slide.title}</h3>
                                    {slide.desc}
                                </div>
                            })}
                        </div>

                        <div className={"pages"}>
                            {this.state.slides.map(slide => {
                                return <div onClick={this.slide.bind(this, slide.number)} className={this.state.slide === slide.number ? "active" :""}>{slide.number + 1}</div>
                            })}
                        </div>
                    </div>

                    <div className={"slider"}>
                        <div className={"slides " + this.state.forward} {...this.props.swipe}>
                            {this.state.slides.map(slide => {
                                return <div className={this.state.slide === slide.number ? (this.state.forward + " " + slide.bg) : slide.bg} />
                            })}
                        </div>

                        <div className={"next-svg"} onClick={() => {
                            this.slide(this.state.slide > 2 ? 0 : this.state.slide + 1);
                        }} />
                    </div>

                    <div className={"pages hide"}>
                        {this.state.slides.map(slide => {
                            return <div onClick={this.slide.bind(this, slide.number)} className={this.state.slide === slide.number ? "active" :""}>{slide.number + 1}</div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    }
});