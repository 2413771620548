import React from "react";
import '../styles/screen3.scss'
import withUseSwipeable from "../components/withUseSwipeable";
import SlideComponent from "../components/SlideComponent";
import disableScroll from 'disable-scroll';

window.autoSlide = function () {
    return true;
}

export default withUseSwipeable(class Screen3 extends SlideComponent {
    constructor(props) {
        super(props);

        console.trace(1232131231232);

        this.state.slides = [{
            number: 0,
            title: 'аналитика и статистика',
            desc: 'просматривайте отчеты в личном кабинете. выгружаете нужные вам отчеты в excel',
            bg: 'slide_1'
        }, {
            number: 1,
            title: 'счета и транзакции',
            desc: 'отслеживайте оплаченные заказы и статус транзакций',
            bg: 'slide_2'
        }, {
            number: 2,
            title: 'чаевые',
            desc: 'отслеживайте динамику по чаевым. быстрый и удобный вывод средств',
            bg: 'slide_3'
        }, {
            number: 3,
            title: 'обратная связь',
            desc: 'узнайте мнение гостей о вашем заведении. можете отследить работу официанта, опираясь на оценки пользователей.',
            bg: 'slide_4'
        }, {
            number: 4,
            title: 'интеграция',
            desc: 'Мы интегрируемся с кассовой системой вашего заведения, чтобы получать информацию о нужных заказах, а также уведомлять сотрудников об оплате счета',
            bg: 'slide_5'
        }, {
            number: 5,
            title: 'адаптивность',
            desc: 'получите возможность взаимодействовать с личным кабинетом через телефон',
            bg: 'slide_6'
        }];

    }

    componentDidMount() {

        let screen3pos = 0;
        let unblockSlide = null;

        let wheel = function (pos) {
            let elem = document.getElementById("wrapper");
            let marginTop = elem.style.marginTop ? -parseInt(elem.style.marginTop) : 0;
            let newMargin = marginTop + pos;

            if(screen3pos === 0) {
                let screen3 = document.getElementById("screen3").getBoundingClientRect();
                screen3pos = screen3.top - 100;
            }

            if(newMargin < 0) newMargin = 0;

            let maxHeight = elem.getBoundingClientRect().height - window.innerHeight;
            if(newMargin > maxHeight) newMargin = maxHeight;

            if(newMargin > screen3pos && pos > 0) {
                if(this.state.slide < 5) {
                    if(unblockSlide === null) {
                        unblockSlide = setTimeout(() => {
                            unblockSlide = null;
                        }, 500);

                        console.log(this.state);
                        this.slide(this.state.slide + 1, false);
                    }

                    newMargin = screen3pos;
                }
            }

            if(newMargin < screen3pos && pos < 0) {
                if(this.state.slide > 0) {

                    if(unblockSlide === null) {
                        unblockSlide = setTimeout(() => {
                            unblockSlide = null;
                        }, 500);

                        this.slide(this.state.slide - 1, false);
                    }

                    newMargin = screen3pos;
                }
            }

            if(unblockSlide == null) {
                elem.style.marginTop = -newMargin + "px";
            }
        }.bind(this);

/*        document.addEventListener("wheel", (ev) => {
            wheel(ev.deltaY)

            ev.preventDefault();
        }, {passive:false});

        let start = {x:0, y:0};
        document.addEventListener("touchstart", (event) => {
            start.y = event.touches[0].pageY;
        }, false);

        document.addEventListener('touchmove', (ev) => {
            wheel((start.y - ev.touches[0].pageY) * 3);
            start.y = ev.touches[0].pageY;

            ev.preventDefault();
        }, {passive:false});*/
    }

    render() {
        return <div id={"screen3"}>
            <h2 className={"hide"}>
                <div className={"for_personal"} />
                личный кабинет
            </h2>

            <div className={"mac-wrapper"} {...this.props.swipe}>
                <img className={"mac"}  src={require("../images/screen3/mac.png")} />

                <div className={"slides " + this.state.forward}>
                    {this.state.slides.map(slide => {
                        return <div className={this.state.slide === slide.number ? (this.state.forward + " " + slide.bg) : slide.bg} />
                    })}
                </div>
            </div>

            <div className={"manager"}>
                <h2>
                    <div className={"for_personal"} />
                    личный кабинет
                </h2>

                <ul>
                    {this.state.slides.map(slide => {
                        return <li onClick={this.slide.bind(this, slide.number)} className={(this.state.slide === slide.number ? "active " : "") + (slide.number > 3 ? " lg-hide": "")}>
                            <h4>{slide.title}</h4>
                            <p>{slide.desc}</p>
                        </li>
                    })}
                </ul>

                <div className={"pages hide"}>
                    {this.state.slides.filter(slide => slide.number < 4).map(slide => {
                        return <div onClick={this.slide.bind(this, slide.number)} className={this.state.slide === slide.number ? "active" :""}>{slide.number + 1}</div>
                    })}
                </div>
            </div>
        </div>;
    }
});