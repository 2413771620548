import React from "react";
import SlideComponent from "../components/SlideComponent";
import '../styles/screen9.scss'

export default class Screen9 extends SlideComponent {
    constructor(props) {
        super(props);

        this.state.slides = [{
            number: 0,
            title: 'потребуется ли гостям установка приложения?',
            desc: <p><span/>нет, ничего устанавливать не понадобится. все, что<br /> нужно гостям - это отсканировать камерой своего<br /> телефона наш QR - код</p>
        },{
            number: 1,
            title: 'с какими кассовыми система работает ваш сервис? ',
            desc: <p><span/>наш сервис имеет интеграции с наиболее<br /> популярными системами - iiko и r-keeper.</p>
        },{
            number: 2,
            title: 'что делать если вашей кассовой системы нет в списке?',
            desc: <p><span/>мы сможем интегрироваться с любой другой системой по<br /> индивидуальному запросу.</p>
        },{
            number: 3,
            title: 'можно ли оплатить счет в ресторане без использования нашего сервиса?',
            desc: <p><span/>безусловно. наш сервис - это лишь более удобная и быстрая<br /> альтернатива классическому способу оплаты. гости всегда<br /> смогут расплатиться так, как им удобнее</p>
        },{
            number: 4,
            title: 'сколько стоит подключение вашего сервиса?',
            desc: <p><span/>на сегодняшний день наш сервис бесплатен для всех заведений</p>
        },{
            number: 5,
            title: 'собирает ли наш сервис какие-либо данные о пользователях?',
            desc: <p><span/>нет, мы не сохраняем никакую информацию о наших гостях</p>
        }];
    }

    render() {
        return <div id={'screen9'}>
            <h2>вопрос - ответ</h2>

            <ul className={"accordio"}>
                {this.state.slides.map(slide => {
                    return <li onClick={this.slide.bind(this, slide.number, true)} className={this.state.slide === slide.number ? "active" :""}>
                        <h4>
                            {slide.number===0 && <div className={"simple-svg " + (this.state.slide === slide.number ? "active" :"")}/>}
                            {slide.title}
                        </h4>
                        {slide.desc}

                        <div className={'button'}>

                        </div>
                    </li>
                })}
            </ul>
        </div>;
    }
}