import React from "react";
import '../styles/screen10.scss'
import forms from "../components/Forms";
import ReCAPTCHA from "react-google-recaptcha";

export default class Screen10 extends React.Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.recaptchaRef = React.createRef();

        this.state = {
            sent: false,
            loading: false,
            userType: "ресторан"
        }
    }

    send(ev) {
        if(this.form.current.checkValidity()) {
            ev.preventDefault();

            let formData = new FormData(this.form.current);
            let format = {};

            // iterate through entries...
            for (let pair of formData.entries()) {
                format[pair[0]] = pair[1]
            }

            this.setState({
                loading: true
            })

            window.grecaptcha.execute(forms.getRecaptchaKey()).then((token) => {
                format['recaptchaToken'] = token;

                forms.feedback(format).then(() => {
                    this.setState({
                        sent: true,
                        loading: false
                    })
                }).catch((err) => {
                    this.setState({
                        loading: false
                    })
                });
            }).catch((err) => {
                alert(err);

                this.setState({
                    loading: true
                })
            });
        }
        else {
            console.log(123);
        }
    }

    render() {
        return <div id={'screen10'}>
            <h2>обратная связь</h2>

            <form ref={this.form}>
                <div className={"row row-form"}>
                    {this.state.sent && <div className={"sent"}>Мы скоро перезвоним вам!</div>}
                        <div>
                        <input type={'text'} placeholder={'фио'} name={'name'} autoComplete="given-name" required />
                        <input type={'tel'} placeholder={'телефон'} name={'phone'} autoComplete="phone" />
                        <input type={'email'} placeholder={'почта'} name={'email'} autoComplete="email" required />
                    </div>
                    <div>
                        <div className={"select-wrapper " + (this.state.selectOpen ? "active" : "")}>
                            <div onClick={() => {
                                this.setState({
                                    selectOpen: true
                                })}}>вы: {this.state.userType}
                            </div>
                            <span>❯</span>
                            <ul>
                                <li onClick={() => {
                                    this.setState({
                                        userType: 'ресторан',
                                        selectOpen: false
                                    })}}>вы: ресторан</li>
                                <li onClick={() => {
                                    this.setState({
                                        userType: 'официант',
                                        selectOpen: false
                                    })}}>вы: официант</li>
                                <li onClick={() => {
                                    this.setState({
                                        userType: 'гость',
                                        selectOpen: false
                                    })}}>вы: гость</li>
                            </ul>
                        </div>

                        <input type={"hidden"} name={'userType'} value={this.state.userType} />
                        <textarea placeholder={'комментарий'} name={'comment'}>
                    </textarea>
                    </div>
                </div>

                <button disabled={this.state.loading || this.state.sent} type={'submit'} onClick={(ev) => this.send(ev)} >отправить</button>

                <div className={"row contacts"}>
                    <div>
                        <p>ООО "СПЛИТ ФУД"</p>
                        <p className={"small"}>
                            <b>Юридический и фактический</b><br />адрес<br/> 117105, МОСКВА Г, УЛ<br/>Нагатинская ул, дом 1,<br/>стр. 14<br/>
                            <b>ОГРН</b>	1227700222969
                        </p>
                    </div>

                    <div>
                        <p>&nbsp;</p>
                        <p className={"small sm_2"}>
                            <b>ИНН</b>	7730277417<br />
                            <b>КПП</b>	773001001<br />
                            <b>Р/с</b>	40702810710001049246<br />
                            <b>К/с</b>	30101810145250000974<br />
                            <b>Банк	АО</b> «Тинькофф Банк»<br />
                            <b>БИК</b>	044525974
                        </p>
                    </div>

                    <div>
                        <a href={"#top"}>
                            <img src={require('../images/screen10/top.png')} width={105} />
                        </a>
                    </div>
                </div>
            </form>

        </div>;
    }
}