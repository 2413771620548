import React from "react";
import '../styles/wrapper.scss'

export default class Wrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

    render() {
        return <div id={"wrapper"}>{this.props.children}</div>;
    }
}