import React from "react";
import '../styles/screen1.scss'
import forms from '../components/Forms'
import ReCAPTCHA from "react-google-recaptcha";

export default class Screen1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sent: false,
            form: false,
            openCash: false,
            openTips: false,
            openOver: false,
            cash: '',
            tips: '',
            over: '',
            loading: false,
        }

        this.form = React.createRef();
        this.recaptchaRef = React.createRef();

        window.addEventListener('form', this.toggleForm.bind(this), false);

    }

    toggleForm(ev) {
        this.setState({
            form: !this.state.form,
            sent: false
        }, (newState) => {
            if(this.state.form) {
                document.body.style.overflowY = "hidden";
            }
            else {
                document.body.style.overflowY = "auto";
            }
        });
    }


    async send(ev) {
        if(this.form.current.checkValidity()) {
            ev.preventDefault();

            let formData = new FormData(this.form.current);
            let format = {};

            // iterate through entries...
            for (let pair of formData.entries()) {
                format[pair[0]] = pair[1]
            }

            this.setState({
                loading: true
            })

            window.grecaptcha.execute(forms.getRecaptchaKey()).then((token) => {
                format['recaptchaToken'] = token;

                forms.request(format).then(() => {
                    this.setState({
                        sent: true,
                        loading: false
                    })
                }).catch((err) => {
                    this.setState({
                        loading: false
                    })
                });
            }).catch((err) => {
                alert(err);

                this.setState({
                    loading: false
                })
            });
        }
        else {
            this.setState({
                openCash: true
            })
        }
    }

    render() {

        return <div>
            {this.state.form && <div className={"form-wrapper"} onClick={(ev) =>
                ev.target.className.toLowerCase() === "form-wrapper" && this.toggleForm()
            }>

                {this.state.sent && <form ref={this.form}>
                    <div className={"close-svg"} onClick={this.toggleForm.bind(this)} />

                    <div className={"th"} />
                    <div className={"th1"} />

                    <input type={'submit'} className={"gray"} value={'вернуться на сайт'}
                           onClick={(ev) => {
                               ev.preventDefault();
                               this.toggleForm();
                           }} />
                </form>}

                {!this.state.sent && <form ref={this.form}>
                    <div className={"close-svg"} onClick={this.toggleForm.bind(this)} />
                    <h2>заполните заявку</h2>
                    <div className={"post-h2"} />

                    <div className="input-placeholder">
                        <input id="requestOrgINN" type="text" required name={'inn'} />
                        <div className="placeholder">
                            инн организации<span>*</span><span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder">
                        <input id="requestName" type="text" required name={'restName'} />
                        <div className="placeholder">
                            название заведения<span>*</span><span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder">
                        <input id="requestUserName" type="text" autoComplete={"name"} required name={'fullName'} />
                        <div className="placeholder">
                            фио<span>*</span><span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder">
                        <input id="requestPhone" type="tel" autoComplete={"tel"} required name={'phone'} />
                        <div className="placeholder">
                            телефон<span>*</span><span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder">
                        <input id="requestMail" type="email" required name={'email'} />
                        <div className="placeholder">
                            e-mail<span>*</span><span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder">
                        <input id="requestUserType" type="text" autoComplete={"street-address"} required name={'city'}/>
                        <div className="placeholder">
                            город<span>*</span><span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder select">
                        <div onClick={() => {
                            this.setState({
                                openCash: !this.state.openCash
                            })}}>
                            <div className="placeholder">
                                кассовая система<span>*</span><span className={"hidden"}>:</span>
                            </div>
                            <div className={'button'} />
                        </div>

                        <div className={"buttons-sel " + (this.state.openCash ? "open" : "")}>
                            <div onClick={() => {
                                this.setState({
                                    cash: 'R-keeper'
                                })}}
                                 className={"select-button " + (this.state.cash === "R-keeper" ? "active" : "")}>
                                R-keeper
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    cash: 'iiko'
                                })}}
                                 className={"select-button " + (this.state.cash === "iiko" ? "active" : "")}>
                                iiko
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    cash: 'Другая'
                                })}}
                                 className={"select-button " + (this.state.cash === "Другая" ? "active" : "")}>
                                Другая
                            </div>

                            <input id="requestName" name='cashType' type="text" value={this.state.cash} required style={{opacity: 0,width:0,height: 0}} />
                        </div>
                    </div>

                    <div className="input-placeholder select">
                        <div onClick={() => {
                            this.setState({
                                openTips: !this.state.openTips
                            })}}>
                            <div className="placeholder">
                                Какой сервис чаевых используете<span className={"hidden"}>:</span>
                            </div>
                            <div className={'button'} />
                        </div>

                        <div className={"buttons-sel " + (this.state.openTips ? "open" : "")}>
                            <div onClick={() => {
                                this.setState({
                                    tips: 'нетмонет'
                                })}}
                                 className={"select-button " + (this.state.tips === "нетмонет" ? "active" : "")}>
                                нетмонет
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    tips: 'втб.чаевые'
                                })}} className={"select-button " + (this.state.tips === "втб.чаевые" ? "active" : "")}>
                                втб.чаевые
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    tips: 'cloudtips'
                                })}} className={"select-button " + (this.state.tips === "cloudtips" ? "active" : "")}>
                                cloudtips
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    tips: 'другой'
                                })}} className={"select-button " + (this.state.tips === "другой" ? "active" : "")}>
                                другой
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    tips: 'никакой'
                                })}} className={"select-button " + (this.state.tips === "никакой" ? "active" : "")}>
                                никакой
                            </div>

                            <div className={"select-button hidden"}>
                                никакой
                            </div>

                            <input id="requestName" name='tipsType' type="text" value={this.state.tips} style={{opacity: 0,width:0,height: 0}} />
                        </div>
                    </div>

                    <div className="input-placeholder">
                        <input id="requestUserType" className={"place"} type="text" placeholder={"empty"} name={'acquiring'} />
                        <div className="placeholder">
                            Ваша ставка по экварингу (%)<span className={"hidden"}>:</span>
                        </div>
                    </div>

                    <div className="input-placeholder select">
                        <div onClick={() => {
                            this.setState({
                                openOver: !this.state.openOver
                            })}}>
                            <div className="placeholder">
                                Примерный оборот руб. / мес. <span className={"hidden"}>:</span>
                            </div>
                            <div className={'button'} />
                        </div>

                        <div className={"buttons-sel " + (this.state.openOver ? "open" : "")}>
                            <div onClick={() => {
                                this.setState({
                                    over: 'до 1 млн'
                                })}}  className={"select-button " + (this.state.over === "до 1 млн" ? "active" : "")}>
                                до 1 млн
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    over: '1 - 3 млн'
                                })}}  className={"select-button " + (this.state.over === "1 - 3 млн" ? "active" : "")}>
                                1 - 3 млн
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    over: '3 - 6 млн'
                                })}}  className={"select-button " + (this.state.over === "3 - 6 млн" ? "active" : "")}>
                                3 - 6 млн
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    over: '6 - 9 млн'
                                })}} className={"select-button " + (this.state.over === "6 - 9 млн" ? "active" : "")}>
                                6 - 9 млн
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    over: 'более 9 млн'
                                })}} className={"select-button " + (this.state.over === "более 9 млн" ? "active" : "")}>
                                более 9 млн
                            </div>

                            <div onClick={() => {
                                this.setState({
                                    over: 'никакой'
                                })}} className={"select-button hidden"}>
                                никакой
                            </div>

                            <input id="requestName" name='orgVal' type="text" value={this.state.over} style={{opacity: 0,width:0,height: 0}} />
                        </div>
                    </div>

                    <input disabled={this.state.loading} type={'submit'} value={'Отправить'}
                           onClick={(ev) => this.send(ev)} />
                </form>}
            </div>}

            <div id={"screen1"}>
                <h1>
                    <div className={"lines"}/>
                    онлайн оплата счёта<br/>и чаевых по QR-коду<br/>в один клик
                </h1>

                <div className={"info"}>порадуйте ваших гостей и сотрудников!</div>

                <div className={"button-group"}>
                    <a className={"button"} onClick={this.toggleForm.bind(this)}>подключиться</a>
                    <div className={"ok"}/>
                </div>

                <img src={require("../images/screen1/right_image.jpg")} />
            </div>
        </div>;
    }
}