import React from "react";
import '../styles/screen6.scss'
import SlideComponent from "../components/SlideComponent";

export default class Screen6 extends SlideComponent {
    constructor(props) {
        super(props);

        this.state.slides = [{
            number: 0,
            title: 'заявка',
            desc: <span>заполните <a className={"ancet"} onClick={() => {
                window.dispatchEvent(new Event('form'));
            }}>анкету</a> на сайте или<br className="mobile" /> закажите обратный звонок</span>,
            bg: 'slide_1'
        },{
            number: 1,
            title: 'документы',
            desc: <span>предоставьте реквизиты вашей компании и<br className="mobile" /> подпишите оферту на подключение</span>,
            bg: 'slide_2'
        },{
            number: 2,
            title: 'интеграция',
            desc: <span>установить наш сервис на вашу кассовую<br className="mobile" /> систему (iiko, r_keeper или др.)</span>,
            bg: 'slide_3'
        },{
            number: 3,
            title: 'обучение',
            desc: <span>подробно расскажем о функциях сервиса<br className="mobile" /> сотрудникам вашего заведения</span>,
            bg: 'slide_4'
        }]
    }

    render() {
        return <div id={"screen6"}>
            <div className={"padding"}>
                <div>
                    <h2>
                        <div className={"round-svg"}/>
                        этапы подключения
                    </h2>

                    <ul className={"accordio"}>
                        {this.state.slides.map(slide => {
                            return <li onClick={this.slide.bind(this, slide.number)} className={this.state.slide === slide.number ? "active" :""}>
                                <h4>
                                    {slide.number===0 && <div className={"simple-svg " + (this.state.slide === slide.number ? "active" :"")}/>}
                                    {slide.title}
                                </h4>
                                <p>{slide.desc}</p>
                            </li>
                        })}
                    </ul>
                </div>
                <div className={"slider-wrapper"}>
                    <div className={"slider"}>
                        {this.state.slides.map(slide => {
                            return <div className={this.state.slide === slide.number ? ("active " + slide.bg) : this.state.prev === slide.number ? ("prev " + slide.bg) : slide.bg} />
                        })}
                    </div>
                </div>
            </div>
        </div>;
    }
}