import React from "react";
import '../styles/screen11.scss'

export default class Screen11 extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div id={'screen11'}>
            <div>
                <div className={"block_1"}>
                    <div className={"icons"}>
                        {/*<a href={"#"} className={"vk"}>&nbsp; </a>
                        <a href={"#"} className={"twi"}>&nbsp;</a>*/}
                        <a href={"https://instagram.com/eat.and.split"} className={"inst"} target={"_blank"}>&nbsp;</a>
                        {/*<a href={"#"} className={"fb"}>&nbsp;</a>*/}
                    </div>

                    <div className={"logo"} />
                </div>

                <div className={"block_2"}>
                    <div>
                        <a href={"mailto: info@eatandsplit.com"}>info@eatandsplit.com</a>
                        <p>Почта для связи</p>
                    </div>
                    <button className={"presentation"}><a href={"https://drive.google.com/file/d/1Ndwug5AjJlF1RgpIo3PtJ5KRbpS36ul4/view?usp=drive_link"}>PDF - презентация о сервисе Eat&Split</a></button>

                    {/* <div>
                        <a href={"tel: 8 (495) 000 00 00"}>8 (495) 000 00 00</a>
                        <p>Контактный центр</p>
                    </div>*/}
                </div>

                <div className={"block_3"}>
                    <div className={"cards"}>
                        <div className={"visa"} />
                        <div className={"mc"} />
                        <div className={"mir"} />
                    </div>

                    <div className={"lic"}>
                        <a href={"/lic1.html"} target={"_blank"}>Пользовательское соглашение</a>
                        <a href={"/lic2.html"} target={"_blank"}>Политика конфиденциальности</a>
                    </div>
                </div>

                <div className={"block_4"}>
                    <div className={"how"}>
                        <a href={"#"}>Как это работает</a>
                        <a href={"#"}>Вопрос-ответ</a>
                        <a href={"#"}>Рестораны</a>
                        <a href={"#"}>Контакты</a>
                    </div>

                    <div className={"other"}>
                        <a href={"/lic4.html"} target={"_blank"}>Правила возврата</a>
                        <a href={"/lic3.html"} target={"_blank"}>Порядок оплаты</a>


                    </div>
                </div>
            </div>

            <div className={"mobile"}>
                <div className={"block_4"}>
                    <div className={"how"}>
                        <a href={"#"}>Как это работает</a>
                        <a href={"#"}>Вопрос-ответ</a>
                        <a href={"#"}>Рестораны</a>
                        <a href={"#"}>Контакты</a>
                    </div>

                    <div className={"other"}>
                        <a href={"/lic4.html"} target={"_blank"}>Правила возврата</a>
                        <a href={"/lic3.html"} target={"_blank"}>Порядок оплаты</a>

                        <div className={"cards"}>
                            <div className={"visa"} />
                            <div className={"mc"} />
                            <div className={"mir"} />
                        </div>
                    </div>
                </div>

                <div className={"lic"}>
                    <a href={"/lic2.html"} target={"_blank"}>Пользовательское соглашение</a>
                    <a href={"/lic2.html"} target={"_blank"}>Политика конфиденциальности</a>

                    <div className={"logo"} />
                </div>
            </div>
        </div>;
    }
}