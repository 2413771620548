import React from "react";
import '../styles/header.scss'

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false
        }
    }

    toggleMenu() {
        this.setState({
            menu: !this.state.menu
        });
    }

    render() {
        return <div id={"header"}>
            {this.state.menu && <div className={"mobile-menu"}>
                <div className={"logo"} />

                <div className={"close-button"} onClick={this.toggleMenu.bind(this)} />

                <ul>
                    <li><a onClick={this.toggleMenu.bind(this)} href="https://admin.eatandsplit.com" target={"_blank"}>личный кабинет</a></li>
                    <li><a onClick={this.toggleMenu.bind(this)} href="#screen2">как это работает</a></li>
                    {/*<li><a onClick={this.toggleMenu.bind(this)} href="#screen7">рестораны</a></li>*/}
                    <li><a onClick={this.toggleMenu.bind(this)} href="#screen5">наши условия</a></li>
                    <li><a onClick={this.toggleMenu.bind(this)} href="#screen6">этапы подключения</a></li>
                    <li><a onClick={this.toggleMenu.bind(this)} href="#screen9">вопрос ответ</a></li>
                    <li><a onClick={this.toggleMenu.bind(this)} href="#screen11">контакты</a></li>
                </ul>
            </div>}

            <div className={"menu"}>
                <div className={"logo"} />

                <ul>
                    <li><a href="#screen2">как это работает?</a></li>
                    {/*<li><a href="#screen7">рестораны</a></li>*/}
                    <li><a href="#screen9">вопрос-ответ</a></li>
                    <li><a href="#screen11">контакты</a></li>
                    <li><a href="https://admin.eatandsplit.com" target={"_blank"}><div className={"menu_admin"} /></a></li>
                </ul>

                <div className={"mobile-menu-buttons"} onClick={this.toggleMenu.bind(this)} />
            </div>
        </div>;
    }
}