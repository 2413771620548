import React from "react";
import '../styles/screen4.scss'

export default class Screen4 extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div id={'screen4'}>

            <div>
                <h2>забудьте об этой проблеме...</h2>
                <p>с нашим сервисом гостям больше не придется считать, кто сколько заказал, а официантам - тратить свое время впустую</p>
            </div>

            <div>
                <img src={require("../images/screen4/hmm.jpg")} width={296} />
            </div>
        </div>;
    }
}